import React from "react";
import { Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CustomTable from "../components/CustomTable";
import ActionButton from "../../../components/ActionButton";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "40px",
    backgroundColor: "white",
    color: "black",
    borderRadius: "6px",
    padding: "20px 30px",
    boxShadow: theme.shadows[6],
    width: "90%",
    maxWidth: "960px",
    height: "75%",
    minHeight: "420px",
    maxHeight: "580px",
    overflowY: "auto",
  },
  table: {
    flex: 1,
    width: "100%",
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  cellCentered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ModalAvvisoControlloAttrezzatura = ({
  open,
  onClose,
  controlliSenzaAttrezzature,
  onConfirm,
}) => {
  const classes = useStyles();
  const formatString = (string) => {
    if (!string) return "";
    return (string[0].toUpperCase() + string.slice(1).toLowerCase()).replace(
      /_/g,
      " "
    );
  };

  const columns = [
    {
      Header: "Titolo procedura",
      id: "titoloProceduraHaccp",
      accessor: (d) => d.titoloProceduraHaccp,
      Cell: (props) => (
        <div className={classes.cellCentered}>{props.value}</div>
      ),
    },
    {
      Header: "Categoria controllo",
      id: "categoriaControllo",
      accessor: (d) => formatString(d.categoriaControllo),
      Cell: (props) => (
        <div className={classes.cellCentered}>{props.value}</div>
      ),
    },
    {
      Header: "Limiti",
      id: "limiti",
      accessor: (d) => d.limiti,
      Cell: (props) => (
        <div className={classes.cellCentered}>{props.value}</div>
      ),
    },
  ];

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <div className={classes.modalContainer}>
        <Typography variant="h4">Attenzione!</Typography>
        <Typography style={{ fontSize: 18, textAlign: "center" }}>
          I seguenti controlli di conformità non sono collegati alle
          attrezzature. Si desidera proseguire ugualmente?
        </Typography>
        <div className={classes.table}>
          <CustomTable
            records={controlliSenzaAttrezzature}
            columns={columns}
            loading={false}
            filterable={true}
            resizable={true}
            sortable={true}
            defaultPageSize={5}
          />
        </div>
        <div>
          <ActionButton
            label="Indietro"
            secondaryButton
            disabled={false}
            grayVersion={false}
            onClick={onClose}
          />
          <ActionButton
            label="Continua"
            disabled={false}
            grayVersion={false}
            onClick={onConfirm}
          />
        </div>
      </div>
    </Modal>
  );
};

ModalAvvisoControlloAttrezzatura.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  controlliSenzaAttrezzature: PropTypes.arrayOf(PropTypes.object).isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ModalAvvisoControlloAttrezzatura;
